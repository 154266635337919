var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    attrs: {
      "no-glutters": ""
    }
  }, [_c('b-col', {
    staticClass: "pb-1",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12",
      "lg": "3"
    }
  }, [_c('multiselect', {
    attrs: {
      "options": _vm.groupBy.options,
      "multiple": false,
      "show-labels": false,
      "allow-empty": false,
      "placeholder": "Select grouping mode"
    },
    on: {
      "input": _vm.onGroupByChange
    },
    model: {
      value: _vm.groupBy.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.groupBy, "selected", $$v);
      },
      expression: "groupBy.selected"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "5",
      "md": "5",
      "lg": "1"
    }
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "title": "Create task in new window"
    },
    on: {
      "click": function click($event) {
        return _vm.addTask('form');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "7",
      "md": "7",
      "lg": "2"
    }
  }, [_c('b-input', {
    ref: "text-input-search",
    attrs: {
      "placeholder": "type to search..."
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.onSearchKeyUp();
      }
    }
  })], 1)], 1), _c('hr'), _vm.isLoading ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticStyle: {
      "color": "lightgrey"
    }
  })], 1)])], 1) : _vm._e(), !_vm.isLoading ? _c('b-row', _vm._l(_vm.categories, function (cat) {
    return _c('b-col', {
      key: cat.name,
      staticClass: "pb-2",
      attrs: {
        "cols": _vm.$isMobile ? '12' : '',
        "md": _vm.$isMobile ? '6' : ''
      }
    }, [_c('b-card', {
      class: _vm.$isMobile ? 'text-center kanban-category height-300' : 'text-center kanban-category height-800',
      attrs: {
        "id": cat.name,
        "no-body": "",
        "border-variant": cat.kanban_color,
        "header-bg-variant": cat.kanban_color,
        "header": "".concat(cat.name, " (").concat(cat.data.length, ")")
      }
    }, [_c('perfect-scrollbar', {
      attrs: {
        "options": _vm.scrollbarOptions
      }
    }, [_c('draggable', _vm._b({
      attrs: {
        "id": cat.name,
        "group": "test-group",
        "move": _vm.onMove
      },
      on: {
        "start": _vm.onStart,
        "end": _vm.onEnd
      },
      model: {
        value: cat.data,
        callback: function callback($$v) {
          _vm.$set(cat, "data", $$v);
        },
        expression: "cat.data"
      }
    }, 'draggable', _vm.dragOptions, false), _vm._l(cat.data.filter(function (t) {
      return t.visible === true;
    }), function (task) {
      return _c('b-card', {
        key: task['ID'],
        staticClass: "kanban-item m-1",
        attrs: {
          "id": task['ID'],
          "bg-variant": "white",
          "border-variant": task.Action ? 'primary' : '',
          "text-variant": ""
        }
      }, [_c('b-container', {
        attrs: {
          "fluid": ""
        }
      }, [_c('b-row', {
        attrs: {
          "align-h": "between"
        }
      }, [_c('b-col', {
        attrs: {
          "cols": "8"
        }
      }, [_vm._v(" " + _vm._s(task["Task Name"]) + " ")]), _c('b-col', {
        staticClass: "d-flex justify-content-center p-2",
        attrs: {
          "cols": "1"
        }
      }, [_c('font-awesome-icon', {
        staticClass: "kanban-item-icon",
        attrs: {
          "icon": "search"
        },
        on: {
          "click": function click($event) {
            return _vm.previewItem(task);
          }
        }
      })], 1), !task['action'] ? _c('b-col', {
        staticClass: "d-flex justify-content-center p-2",
        attrs: {
          "cols": "1"
        }
      }, [_c('font-awesome-icon', {
        staticClass: "kanban-item-icon",
        attrs: {
          "icon": "pencil-alt"
        },
        on: {
          "click": function click($event) {
            return _vm.editItem(task);
          }
        }
      })], 1) : _vm._e(), task['action'] ? _c('b-col', {
        staticClass: "justify-content-center p-2",
        attrs: {
          "cols": "1"
        }
      }, [task.isCompleting ? _c('b-spinner', {
        attrs: {
          "small": "",
          "type": "grow"
        }
      }) : _vm._e(), !task.isCompleting ? _c('font-awesome-icon', {
        staticClass: "kanban-item-icon",
        staticStyle: {
          "color": "#20a8d8"
        },
        attrs: {
          "title": "Submit activity",
          "icon": "bolt"
        },
        on: {
          "click": function click($event) {
            return _vm.submitActivity(task.ID);
          }
        }
      }) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('hr'), _c('b-row', {
        attrs: {
          "align-h": "between"
        }
      }, [_c('b-col', {
        attrs: {
          "cols": "5"
        }
      }, [_c('b-badge', {
        attrs: {
          "variant": _vm.statusColor(task['Status'])
        }
      }, [_vm._v(" " + _vm._s(task["Status"]) + " ")]), _c('b-badge', {
        attrs: {
          "variant": _vm.priorityColor(task['Priority'])
        }
      }, [_vm._v(" " + _vm._s(task["Priority"]) + " ")])], 1), _c('b-col', {
        staticClass: "kanban-item-date pl-0 pr-2",
        attrs: {
          "sm": "5",
          "md": "5",
          "lg": "5"
        }
      }, [_c('inline-date-picker', {
        attrs: {
          "id": "due-date-".concat(task['ID']),
          "value-single": task['Due Date'],
          "format": "MMM DD",
          "hide-label": true,
          "readonly": false,
          "mode": _vm.FORMCONTROLMODE.VIEW
        },
        on: {
          "changed": _vm.updateDateField
        }
      })], 1)], 1)], 1);
    }), 1)], 1)], 1)], 1);
  }), 1) : _vm._e()], 1), _c('b-modal', {
    ref: "preview-modal",
    staticStyle: {
      "max-width": "500px !important"
    },
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "xl",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Task Name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.taskName) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Project Name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.projectName) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Assignor: ")]), _vm._v(" " + _vm._s(_vm.previewModal.creator) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Assigned to: ")]), _vm._v(" " + _vm._s(_vm.previewModal.assigned_to) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.startDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.dueDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Time start: ")]), _vm._v(" " + _vm._s(_vm.previewModal.timeStart) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Time end: ")]), _vm._v(" " + _vm._s(_vm.previewModal.timeEnd) + " ")])], 1), _c('b-row'), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Description: ")]), _c('b-card-body', [_c('div', {
    staticClass: "preview-report",
    domProps: {
      "innerHTML": _vm._s(_vm.previewModal.description)
    }
  })])], 1)], 1), _c('b-row', [_c('b-col')], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "btn-group1 text-center"
  }, [_vm.previewModal.task && _vm.previewModal.task.action ? _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-primary",
      "title": "Submit activity"
    },
    on: {
      "click": function click($event) {
        return _vm.submitActivity(_vm.previewModal.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewModal.task && _vm.previewModal.task.isCompleting === true
    }
  }), !(_vm.previewModal.task && _vm.previewModal.task.isCompleting === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "bolt"
    }
  }) : _vm._e(), _vm._v(" Submit activity ")], 1) : _vm._e(), !(_vm.previewModal.task && _vm.previewModal.task.Recurring) && _vm.previewModal.task && !_vm.previewModal.task.action ? _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-success",
      "title": "Complete task"
    },
    on: {
      "click": function click($event) {
        return _vm.completeTask(_vm.previewModal.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewModal.task && _vm.previewModal.task.isCompleting === true
    }
  }), !(_vm.previewModal.task && _vm.previewModal.task.isCompleting === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "check-circle"
    }
  }) : _vm._e(), _vm._v(" Complete ")], 1) : _vm._e(), _vm.previewModal.task && _vm.previewModal.task.Recurring ? _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-secondary",
      "title": "Postpone task"
    },
    on: {
      "click": function click($event) {
        return _vm.postponeTask(_vm.previewModal.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewModal.task.isPostponing === true
    }
  }), !(_vm.previewModal.task.isPostponing === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "clock"
    }
  }) : _vm._e(), _vm._v(" Postpone ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-info",
      "title": "Edit task"
    },
    on: {
      "click": function click($event) {
        return _vm.editTask(_vm.previewModal.ID);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "pencil-alt"
    }
  }), _vm._v(" Edit ")], 1), _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-info",
      "title": "Clone task"
    },
    on: {
      "click": function click($event) {
        return _vm.cloneTask(_vm.previewModal.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewModal.task && _vm.previewModal.task.isCloning === true
    }
  }), !(_vm.previewModal.task && _vm.previewModal.task.isCloning === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "clone"
    }
  }) : _vm._e(), _vm._v(" Clone ")], 1), _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-warning",
      "title": "Email task"
    },
    on: {
      "click": function click($event) {
        return _vm.emailTask(_vm.previewModal.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewModal.task && _vm.previewModal.task.isEmailing === true
    }
  }), !(_vm.previewModal.task && _vm.previewModal.task.isEmailing === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "envelope"
    }
  }) : _vm._e(), _vm._v(" Email ")], 1), _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-dark",
      "title": "Open in new window"
    },
    on: {
      "click": function click($event) {
        return _vm.openTaskInNewWindow(_vm.previewModal.ID);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "share"
    }
  }), _vm._v(" New window ")], 1), _c('b-button', {
    staticClass: "action-btn",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }