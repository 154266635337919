var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "tasks",
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "loaded": _vm.onFilteringPanelLoad,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mode === 'table' && _vm.isDataVisible,
      expression: "mode === 'table' && isDataVisible"
    }]
  }, [_c('b-col', [_c('tasks-table', {
    ref: "tasks",
    attrs: {
      "rows-per-page": 20,
      "autoload": false,
      "selectable-rows": true,
      "filter-by-column": true
    },
    on: {
      "loaded": _vm.onTasksLoad
    }
  })], 1)], 1), _vm.mode === 'kanban' && _vm.isDataVisible ? _c('b-row', [_c('b-col', [_c('kanban', {
    ref: "tasks",
    on: {
      "loaded": _vm.onTasksLoad
    }
  })], 1)], 1) : _vm._e(), _vm.mode === 'gantt' && _vm.isDataVisible ? _c('b-row', [_c('b-col', [_c('gantt', {
    ref: "tasks",
    on: {
      "loaded": _vm.onTasksLoad
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }