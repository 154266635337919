<template>
  <div class="animated fadeIn">
    <b-container fluid>
      <b-row no-glutters>
        <b-col cols="12" sm="12" md="12" lg="3" class="pb-1">
          <multiselect
            v-model="groupBy.selected"
            :options="groupBy.options"
            @input="onGroupByChange"
            :multiple="false"
            :show-labels="false"
            :allow-empty="false"
            placeholder="Select grouping mode"
          />
        </b-col>
        <b-col cols="5" md="5" lg="1">
          <b-button
            variant="outline-dark"
            @click="addTask('form')"
            title="Create task in new window"
            class="m-1"
          >
            <font-awesome-icon icon="plus" /> Create
          </b-button>
        </b-col>
        <b-col cols="7" md="7" lg="2">
          <b-input
            :ref="`text-input-search`"
            placeholder="type to search..."
            @keyup="onSearchKeyUp()"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row v-if="isLoading">
        <b-col>
          <div class="text-center">
            <b-spinner style="color:lightgrey;" />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col
          :cols="$isMobile ? '12' : ''"
          :md="$isMobile ? '6' : ''"
          v-for="cat in categories"
          :key="cat.name"
          class="pb-2"
        >
          <b-card
            :id="cat.name"
            no-body
            :border-variant="cat.kanban_color"
            :header-bg-variant="cat.kanban_color"
            :header="`${cat.name} (${cat.data.length})`"
            :class="
              $isMobile
                ? 'text-center kanban-category height-300'
                : 'text-center kanban-category height-800'
            "
          >
            <perfect-scrollbar :options="scrollbarOptions">
              <draggable
                :id="cat.name"
                v-model="cat.data"
                group="test-group"
                v-bind="dragOptions"
                @start="onStart"
                @end="onEnd"
                :move="onMove"
              >
                <b-card
                  :id="task['ID']"
                  v-for="task in cat.data.filter(t => t.visible === true)"
                  :key="task['ID']"
                  bg-variant="white"
                  :border-variant="task.Action ? 'primary' : ''"
                  text-variant=""
                  class="kanban-item m-1"
                >
                  <b-container fluid>
                    <b-row align-h="between">
                      <b-col cols="8">
                        {{ task["Task Name"] }}
                      </b-col>

                      <b-col cols="1" class="d-flex justify-content-center p-2">
                        <font-awesome-icon
                          class="kanban-item-icon"
                          icon="search"
                          @click="previewItem(task)"
                        />
                      </b-col>
                      <b-col
                        v-if="!task['action']"
                        cols="1"
                        class="d-flex justify-content-center p-2"
                      >
                        <font-awesome-icon
                          class="kanban-item-icon"
                          icon="pencil-alt"
                          @click="editItem(task)"
                        />
                      </b-col>
                      <b-col
                        v-if="task['action']"
                        cols="1"
                        class=" justify-content-center p-2"
                      >
                        <b-spinner v-if="task.isCompleting" small type="grow" />
                        <font-awesome-icon
                          v-if="!task.isCompleting"
                          class="kanban-item-icon"
                          style="color: #20a8d8;"
                          title="Submit activity"
                          icon="bolt"
                          @click="submitActivity(task.ID)"
                        />
                      </b-col>
                    </b-row>
                  </b-container>
                  <hr />
                  <b-row align-h="between" class="">
                    <b-col cols="5">
                      <b-badge :variant="statusColor(task['Status'])">
                        {{ task["Status"] }}
                      </b-badge>
                      <b-badge :variant="priorityColor(task['Priority'])">
                        {{ task["Priority"] }}
                      </b-badge>
                    </b-col>
                    <b-col
                      sm="5"
                      md="5"
                      lg="5"
                      class="kanban-item-date pl-0 pr-2"
                    >
                      <inline-date-picker
                        :id="`due-date-${task['ID']}`"
                        :value-single="task['Due Date']"
                        format="MMM DD"
                        :hide-label="true"
                        :readonly="false"
                        :mode="FORMCONTROLMODE.VIEW"
                        @changed="updateDateField"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </draggable>
            </perfect-scrollbar>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      ref="preview-modal"
      centered
      hide-footer
      size="xl"
      style="max-width: 500px !important;"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col lg="3">
              <strong>Task Name: </strong> {{ previewModal.taskName }}
            </b-col>
            <b-col lg="3">
              <strong>Project Name: </strong> {{ previewModal.projectName }}
            </b-col>

            <b-col lg="3">
              <strong>Assignor: </strong> {{ previewModal.creator }}
            </b-col>
            <b-col lg="3">
              <strong>Assigned to: </strong> {{ previewModal.assigned_to }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <strong>Date: </strong> {{ previewModal.startDate }}
            </b-col>
            <b-col lg="3">
              <strong>Date: </strong> {{ previewModal.dueDate }}
            </b-col>
            <b-col lg="3">
              <strong>Time start: </strong> {{ previewModal.timeStart }}
            </b-col>
            <b-col lg="3">
              <strong>Time end: </strong> {{ previewModal.timeEnd }}
            </b-col>
          </b-row>
          <b-row />
          <b-row>
            <b-col>
              <strong>Description: </strong>
              <b-card-body>
                <div class="preview-report" v-html="previewModal.description" />
              </b-card-body>
            </b-col>
          </b-row>
          <b-row>
            <b-col />
          </b-row>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <div class="btn-group1 text-center">
          <b-button
            v-if="previewModal.task && previewModal.task.action"
            variant="outline-primary"
            title="Submit activity"
            class="action-btn"
            @click="submitActivity(previewModal.ID)"
          >
            <clip-loader
              class=""
              :size="13"
              color="#36D7B7"
              :loading="
                previewModal.task && previewModal.task.isCompleting === true
              "
            />
            <font-awesome-icon
              icon="bolt"
              v-if="
                !(previewModal.task && previewModal.task.isCompleting === true)
              "
            />
            Submit activity
          </b-button>

          <b-button
            v-if="
              !(previewModal.task && previewModal.task.Recurring) &&
                previewModal.task &&
                !previewModal.task.action
            "
            variant="outline-success"
            title="Complete task"
            class="action-btn"
            @click="completeTask(previewModal.ID)"
          >
            <clip-loader
              class=""
              :size="13"
              color="#36D7B7"
              :loading="
                previewModal.task && previewModal.task.isCompleting === true
              "
            />
            <font-awesome-icon
              icon="check-circle"
              v-if="
                !(previewModal.task && previewModal.task.isCompleting === true)
              "
            />
            Complete
          </b-button>
          <b-button
            v-if="previewModal.task && previewModal.task.Recurring"
            variant="outline-secondary"
            title="Postpone task"
            class="action-btn"
            @click="postponeTask(previewModal.ID)"
          >
            <clip-loader
              class=""
              :size="13"
              color="#36D7B7"
              :loading="previewModal.task.isPostponing === true"
            />
            <font-awesome-icon
              icon="clock"
              v-if="!(previewModal.task.isPostponing === true)"
            />
            Postpone
          </b-button>

          <b-button
            variant="outline-info"
            @click="editTask(previewModal.ID)"
            title="Edit task"
            class="action-btn"
          >
            <font-awesome-icon icon="pencil-alt" /> Edit
          </b-button>
          <b-button
            variant="outline-info"
            @click="cloneTask(previewModal.ID)"
            title="Clone task"
            class="action-btn"
          >
            <clip-loader
              class=""
              :size="13"
              color="#36D7B7"
              :loading="
                previewModal.task && previewModal.task.isCloning === true
              "
            />
            <font-awesome-icon
              icon="clone"
              v-if="
                !(previewModal.task && previewModal.task.isCloning === true)
              "
            />
            Clone
          </b-button>

          <b-button
            variant="outline-warning"
            @click="emailTask(previewModal.ID)"
            title="Email task"
            class="action-btn"
          >
            <clip-loader
              class=""
              :size="13"
              color="#36D7B7"
              :loading="
                previewModal.task && previewModal.task.isEmailing === true
              "
            />
            <font-awesome-icon
              icon="envelope"
              v-if="
                !(previewModal.task && previewModal.task.isEmailing === true)
              "
            />
            Email
          </b-button>

          <b-button
            variant="outline-dark"
            @click="openTaskInNewWindow(previewModal.ID)"
            title="Open in new window"
            class="action-btn"
          >
            <font-awesome-icon icon="share" /> New window
          </b-button>

          <b-button
            variant="outline-dark"
            class="action-btn"
            @click="closeModal()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import {
  submitActivity,
  completeTask,
  cloneTask,
  emailTask,
  postponeTask
} from "./helpers.js";

import InlineDatePicker from "@/components/InlineDatePicker";

import draggable from "vuedraggable";

import { FORMCONTROLMODE } from "@/shared/constants";

export default {
  name: "Kanban",
  props: [],
  components: {
    draggable,
    Multiselect,
    InlineDatePicker
  },
  data: function() {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isDragging: false,

      isLoading: false,
      groupBy: {
        selected: "Timeline",
        options: ["Priority", "Status", "Timeline"]
      },
      scrollbarOptions: {
        suppressScrollX: true
      },
      rawData: [],
      dataTable: {
        dataSet: []
      },
      groupCategories: {
        Status: [],
        Priority: [],
        Timeline: [
          {
            id: 1,
            name: "Overdue",
            kanban_color: "secondary"
          },
          {
            id: 2,
            name: "Today",
            kanban_color: "danger"
          },
          {
            id: 3,
            name: "Tomorrow",
            kanban_color: "warning"
          },
          {
            id: 4,
            name: "Next 7 days",
            kanban_color: "success"
          },
          {
            id: 5,
            name: "Future",
            kanban_color: "primary"
          }
        ]
      },
      categories: [],
      previewModal: {
        task: undefined,
        ID: "",
        creator: "",
        assigned_to: "",
        taskName: "",
        startDate: "",
        dueDate: "",
        timeStart: "",
        timeEnd: "",
        description: ""
      }
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false, // !this.editable,
        ghostClass: "ghost"
      };
    }
  },
  mounted: async function() {
    //this.getData();
  },
  methods: {
    onSearchKeyUp() {
      let text = this.$refs["text-input-search"].vModelValue;

      if (text.trim() === "") {
        this.categories.forEach(cat => {
          cat.data.forEach(i => (i.visible = true));
        });
      } else {
        this.categories.forEach(cat => {
          cat.data.forEach(
            i =>
              (i.visible = i["Task Name"]
                .toLowerCase()
                .includes(text.toLowerCase()))
          );
        });
      }

      this.$forceUpdate();
    },
    submitActivity(id) {
      submitActivity(id, this);
    },

    completeTask(id) {
      completeTask(id, this);
    },
    postponeTask(id) {
      postponeTask(id, this);
    },
    /*
        editTask(id){

            editTask(id, this)

        },
        */
    cloneTask(id) {
      cloneTask(id, this, false);
    },
    emailTask(id) {
      emailTask(id, this, false);
    },
    editTask: function(id) {
      this.$router.push({
        name: "Task submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },

    updateDateField(e) {
      this.onDueDateChange(e.id, e.valueSingleAsString, e.mode, e.value);
    },
    onDueDateChange(id, strValue, mode, value) {
      let self = this;
      let newValue = moment(value.startDate).format("YYYY-MM-DD");
      let taskid = id.replace("due-date-", "");
      let task = this.dataTable.dataSet.find(t => t["ID"] === taskid);

      let payload = {
        due_date: newValue
      };
      this.$api
        .put(`tasks/${task["ID"]}`, payload)
        .then(response => {
          task["Due Date"] = newValue;

          self.sortData(this.groupBy.selected);

          self.$form.makeToastInfo(response.message);
        })
        .catch(function(error) {
          self.$form.makeToastError(error.response.statusText);
        });
    },
    addTask: function(mode) {
      if (mode === "form") {
        this.$router.push({
          name: "Task submission",
          params: {
            action: "create"
          }
        });

        return;
      }
    },
    previewItem(task) {
      //let task = this.dataTable.dataSet.find(item => item.ID === id);

      //if (!task) return;

      this.previewModal.task = task;

      this.previewModal.ID = task.ID;

      this.previewModal.title = `Task #${task.ID}`;

      this.previewModal.projectName = task["Project Name"];
      this.previewModal.creator = task["Creator"];
      this.previewModal.assigned_to = task["Assigned To"];
      this.previewModal.startDate = task["Start Date"];
      this.previewModal.dueDate = task["Due Date"];
      this.previewModal.timeStart = task["Start Time"];
      this.previewModal.timeEnd = task["End Time"];
      this.previewModal.taskName = task["Task Name"];
      this.previewModal.description = task["Description"];

      this.$refs["preview-modal"].show();
    },
    closeModal: function() {
      this.$refs["preview-modal"].hide();
    },

    viewItem() {},
    editItem: function(task) {
      this.$router.push({
        name: "Task submission",
        params: {
          action: "edit",
          id: task["ID"]
        }
      });
    },
    onStart() {
      this.isDragging = true;
    },
    onEnd(e) {
      this.isDragging = false;

      if (e.from.id === e.to.id) return;

      let targetGroup = e.to.id;
      let selectedTask = this.dataTable.dataSet.find(t => t["ID"] == e.item.id);

      let payload = {},
        field = "",
        newValue = "";

      if (this.groupBy.selected === "Status") {
        /*if task-action and status completed */
        if (targetGroup === "Completed" && selectedTask.action !== null) {
          this.$form.makeToastError(
            "This task will be auto completed after activity submission"
          );

          return false;
        }

        field = "Status";
        newValue = targetGroup;
        payload.status = {
          label: newValue
        };
      }

      if (this.groupBy.selected === "Priority") {
        field = "Priority";
        newValue = targetGroup;
        payload.priority = {
          label: newValue
        };
      }

      if (this.groupBy.selected === "Timeline") {
        if (targetGroup === "Overdue") {
          newValue = this.$nowClient
            .clone()
            .subtract(6, "day")
            .format("YYYY-MM-DD");
        }
        if (targetGroup === "Today") {
          newValue = this.$nowClient.clone().format("YYYY-MM-DD");
        }
        if (targetGroup === "Tomorrow") {
          newValue = this.$nowClient
            .clone()
            .add(1, "day")
            .format("YYYY-MM-DD");
        }

        if (targetGroup === "Next 7 days") {
          //newValue = this.$nowClient.clone().add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          //skip 'tomorrow' - add 2 days
          newValue = this.$nowClient
            .clone()
            .add(2, "day")
            .format("YYYY-MM-DD");
        }
        if (targetGroup === "Future") {
          newValue = this.$nowClient
            .clone()
            .add(30, "day")
            .format("YYYY-MM-DD");
        }

        field = "Due Date";
        selectedTask["Start Date"] = newValue;
        selectedTask["Due Date"] = newValue;

        payload.start_date = newValue;
        payload.due_date = newValue;
      }

      let self = this;

      selectedTask[field] = newValue;

      self.sortData(this.groupBy.selected);

      this.$api
        .put(`tasks/${selectedTask["ID"]}`, payload)
        .then(response => {
          self.$form.makeToastInfo(response.message);
        })
        .catch(function(error) {
          self.drawDataTable();

          self.$form.makeToastError(error.response.statusText);
        });
    },
    onMove(e) {
      if (e.to.id === "Completed" && e.draggedContext.element.action !== null) {
        return false;
      }
    },

    /*
        onMove({
            relatedContext,
            draggedContext
        }) {

            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;

            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            );
        },
        */
    onGroupByChange(e) {
      this.sortData(e);
    },
    statusColor(value) {
      let color = "secondary";

      if (value === "In Progress") color = "warning";
      if (value === "Planned") color = "secondary";
      if (value === "Completed") color = "success";

      return color;
    },
    priorityColor(value) {
      let color = "secondary";

      if (value === "Low") color = "success";
      if (value === "Medium") color = "warning";
      if (value === "High") color = "danger";

      return color;
    },
    formatDate(date) {
      return moment(date).format("MMM DD");
    },
    async getData() {
      await this.drawDataTable();
    },

    async drawDataTable() {
      this.isLoading = true;

      let response = await this.$api.get("tasks");

      this.rawData = response;

      this.isLoading = false;
      this.isLoaded = true;

      this.dataTable.dataSet = this.rawData;

      //create array of selected values for multiselect input
      this.dataTable.dataSet.forEach(row => {
        let values = [];

        if (row["Assigned To"].length > 0) {
          let ids = row.assigned_to_ids.split(",");
          let names = row["Assigned To"].split(",");

          for (let i = 0; i < ids.length; i++) {
            values.push({
              id: ids[i].trim(),
              label: names[i].trim()
            });
          }
        }

        row.action = this.$helpers.getJSONObject(row["Action"]);

        row.assignedTo = values;
        row.visible = true;
      });

      this.sortData(this.groupBy.selected);

      this.$emit("loaded");
    },
    sortData: async function(e) {
      if (!e) return;

      this.isLoading = true;

      if (!this.groupCategories.Priority.length)
        this.groupCategories.Priority = await this.$api.get("tasks/priorities");
      if (!this.groupCategories.Status.length)
        this.groupCategories.Status = await this.$api.get("tasks/statuses");

      let self = this;

      self.categories = [];

      this.groupCategories[e].forEach(c => {
        self.categories.push({
          id: c.id,
          name: c.name,
          kanban_color: c.kanban_color,
          data: []
        });
      });

      self.categories = self.categories.sort(
        (a, b) => parseInt(a.id) - parseInt(b.id)
      );

      if (this.groupBy.selected !== "Timeline") {
        //this.dataTable.dataSet.filter(t => t['Status'] !== 'Completed' && moment(t['Due Date']).isAfter(this.$nowClient.clone().subtract(60, 'day'))).forEach(t => {
        //this.dataTable.dataSet.filter(t => t['Status'] !== 'Completed').forEach(t => {

        this.dataTable.dataSet.forEach(t => {
          let container = self.categories.find(
            c => c.name === t[self.groupBy.selected]
          );

          if (container) container.data.push(t);
          else {
            //debugger
          }
        });
      } else {
        //this.dataTable.dataSet.filter(t => t['Status'] !== 'Completed' ).forEach(t => {
        //this.dataTable.dataSet.filter(t => t['Status'] !== 'Completed' && moment(t['Due Date']).isAfter(this.$nowClient.clone().subtract(60, 'day'))).forEach(t => {

        this.dataTable.dataSet.forEach(t => {
          if (
            moment(t["Due Date"]).isBefore(this.$nowClientAsString) &&
            t["Status"] !== "Completed"
          )
            self.categories.find(c => c.name === "Overdue").data.push(t);

          //let s = this.$nowClient.format('YYYY-MM-DD')
          if (moment(t["Due Date"]).isSame(this.$nowClientAsString)) {
            self.categories.find(c => c.name === "Today").data.push(t);
          }

          //let d = this.$nowClient.clone().add(1, 'day').format('YYYY-MM-DD')
          if (
            moment(t["Due Date"]).isSame(
              this.$nowClient
                .clone()
                .add(1, "day")
                .format("YYYY-MM-DD")
            )
          ) {
            self.categories.find(c => c.name === "Tomorrow").data.push(t);
          }

          let next7daysStart = this.$nowClient
            .clone()
            .add(2, "day")
            .format("YYYY-MM-DD");
          let next7daysEnd = this.$nowClient
            .clone()
            .add(8, "day")
            .format("YYYY-MM-DD");

          if (
            moment(t["Due Date"]).isSameOrAfter(next7daysStart) &&
            moment(t["Due Date"]).isSameOrBefore(next7daysEnd)
          ) {
            self.categories.find(c => c.name === "Next 7 days").data.push(t);
          }

          if (moment(t["Due Date"]).isAfter(next7daysEnd))
            self.categories.find(c => c.name === "Future").data.push(t);

          /*
                    let nextWeekStart = this.$nowClient.clone().add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
                    let nextWeekEnd = this.$nowClient.clone().add(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')

                    if (
                        moment(t['Due Date']).isSameOrAfter(nextWeekStart) &&
                        moment(t['Due Date']).isSameOrBefore(nextWeekEnd)) {

                        self.categories.find(c => c.name === 'Next 7 days').data.push(t)
                    }

                    if (moment(t['Due Date']).isAfter(nextWeekEnd)) self.categories.find(c => c.name === 'Future').data.push(t)

                    */
        });
      }

      this.isLoading = false;
    },
    loading(status) {
      this.isLoading = status;
    },
    openTaskInNewWindow(task_id) {
      let routeData = this.$router.resolve({
        name: "Task submission",
        params: {
          id: task_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  watch: {
    "dataTable.dataSet"() {
      this.sortData(this.groupBy.selected);
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
};
</script>

<style scoped>
.action-btn {
  width: 10em;
  margin: 1px;
}
.kanban-category {
  max-width: 25em;
  margin: 0 auto;
  float: none;
}

::v-deep .modal-xl {
  max-width: 900px !important;
}

.height-300 {
  height: 300px;
}

.height-800 {
  height: 800px;
}

.kanban-item {
  cursor: pointer;
}

.kanban-item-icon {
  color: gray;
}
.kanban-item-date .form-control {
  font-size: 12px !important;
  padding-left: 2px;
}
</style>
